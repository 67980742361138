@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "ARTWALL";
  src: url("./fonts/artwall-rubash.ttf");
}

@font-face {
  font-family: "COMFORTAA";
  src: url("./fonts/comfortaa.ttf");
}

@font-face {
  font-family: "COMFORTAABOLD";
  src: url("./fonts/comfortaa-bold.ttf");
}

@font-face {
  font-family: "COMFORTAALIGHT";
  src: url("./fonts/comfortaa-light.ttf");
}

@layer base {
  * {
    font-family: ARTWALL, system-ui, sans-serif;
    
  }
  svg {
    color: #ffffff !important;
  }
  .map {
    filter: grayscale(100%) invert(98%) contrast(100%) ;
  }

    .picker {
      grid-column: 5/10;
      grid-row: 2/4;
      background: cornflowerblue;
      color: black;
      border: none !important;
    
    }
    .react-date-picker__wrapper {

      border: none !important;
    }

    body { font-family: sans-serif; }

.scene {
  display: inline-block;
  width: 200px;
  height: 260px;
/*   border: 1px solid #CCC; */
  margin: 40px 0;
  perspective: 600px;
}

.card {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transform-style: preserve-3d;
  transform-origin: center right;
  transition: transform 1s;
}

.card.is-flipped {
  transform: translateX(-100%) rotateY(-180deg);
}

.card__face {
  position: absolute;
  width: 100%;
  height: 100%;
  line-height: 260px;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 40px;
  backface-visibility: hidden;
}

.card__face--front {
  background: crimson;
}

.card__face--back {
  background: slateblue;
  transform: rotateY(180deg);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
     box-shadow: 0 0 0 30px #0a1628 inset !important;
     -webkit-text-fill-color: white !important;
     
}

video::-webkit-media-controls {
  display: none;
}


    
  }
